<template>
  <div>GlobalOptionDialog {{GlobalOptionDialog}}
    <v-dialog v-model="GlobalOptionDialog"          			
        max-width="400px"transition="dialog-bottom-transition">
        <v-card-title>
        <v-btn fab icon class="soloactionicon" @click="CancelFieldDialog()">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-card-title>
        <OptionSetFieldConfig :OptionField="OptionField"
        :NewOptionLevel="NewOptionLevel" @SaveOptionField="SaveOptionField" :ParentOption="ParentOption" @CancelFieldDialog="CancelFieldDialog"
        v-if="GlobalOptionDialog" :PluginOptions="PluginOptions"
        />
    </v-dialog>
   <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      Global Option Sets
      </v-card-title>
       <v-card elevation="0" class="siteconfigcard">
        <v-card-title v-if="!ActiveLibTypeComp" class="mediumoverline">
          List<v-spacer></v-spacer>
            <v-btn @click="ActivateGlobalOptionDialog()" outlined><v-icon>mdi-plus</v-icon>Add New</v-btn>
        </v-card-title> 
        <v-card-text>
            <v-list-item v-for="opt in GlobalOptionSets" :key="opt.itemObjKey">
                {{opt.DisplayName}}
            </v-list-item>
        </v-card-text>
       </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import OptionSetFieldConfig from '@/components/Database/Fields/Input/OptionSetFieldConfig'

export default {
    props: ['System','SystemEntities'],
    components: {OptionSetFieldConfig},
    data() {
        return {
            GlobalOptionDialog: false,
            OptionField: {
                  Name: '',
                  DisplayName: '',
                  Type: 'Global Option Set',
                  lastassignedinteger: 1000000,
                  Options: []
              },
            DefaultOptionField: {
                  Name: '',
                  DisplayName: '',
                  Type: 'Global Option Set',
                  lastassignedinteger: 1000000,
                  Options: []
              }
        }
    },	
    computed:{
        AddTitle(){
            return this.NewOptionLevel === 1 ? 'Add an Option' : 'Add an Option to "'+this.ParentOption.DisplayName+'"'
        },
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },    
      PluginOptions(){
        let options = ['Campaign_Builder','Featured_Members','Web_App_Builder','Site_Articles','Site_Blogs','Site_Events','Site_Meetups','Vacancies']
        return options.filter(opt => {
            return this.PluginDataBase[opt] && this.PluginDataBase[opt].Active
        })
      },
        GlobalOptionSets(){
            return this.$store.state.GlobalOptionSets
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        SaveOptionField(){
            console.log(this.OptionField)            
        },
        ActivateGlobalOptionDialog(){
            this.GlobalOptionDialog = true
        },
        CancelFieldDialog(){
            this.GlobalOptionDialog = false
            this.OptionField = Object.assign({},this.DefaultOptionField)
        },
    }
}
</script>

<style>

</style>



